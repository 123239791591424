/* tslint:disable:no-console */

import { register } from 'register-service-worker';

//if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === '') {
if (true) {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB',
      );
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updated () {
      // console.log('New content is available; please refresh.');
  
      // const script = document.createElement('script');
      // script.setAttribute('type', 'text/javascript');
      // script.innerHTML = 'window.location.reload(true);';
  
      // const meta = document.createElement('meta');
      // meta.setAttribute('http-equiv', 'refresh');
      // meta.setAttribute('content', '0;');
  
      // const noscript = document.createElement('noscript');
      // noscript.appendChild(meta);
  
      // const body = document.getElementsByTagName('body')[0];
      // body.appendChild(script);
      // body.appendChild(noscript);
      console.log('New content is available; Refresh...')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    },
    offline() {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    },
  });
}
